<template>
    <div class="content-box">
        <h4 class="text-center my-30">404 Page Not Found</h4>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>